import Header from "../Components/header";
import Footer from "../Components/footer";
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

const Artistas = () => {    
    
    return (
        <div className="container">
            <Header/>
            <div className="content">
                <div className="divisor">
                    <div className="divisorTitle1">CONHEÇA NOSSOS</div>
                    <div className="divisorTitle2"><hr className="line1"/>ARTISTAS</div>
                    <div className="divisorSubTitle">POSSUÍMOS UMA EQUIPE EXCELENTE, TRAZENDO QUALIDADE E PROFISSIONALISMO!</div>
                </div>
                {/* ARTISTA 1 */}
                <div className="container-artista">
                    <div className="artista">
                        <img className="artista-foto" src="./images/artista1.png"/>
                        <div className="border-foto-back"/>
                        <div className="border-foto-front"/>
                    </div>
                    <div className="artista-info">
                        <div className="bloco1">
                            <div className="nome">LARISSA ARAUJO</div>
                            <div className="insta">@larissaaraujotattoo</div>
                        </div>
                        <div className="bloco2">
                            <div className="bio-title">Biografia</div>
                            <div className="bio-text">Com uma paixão por capturar cada detalhe, sou especializada em tatuagens de realismo, pontilhismo e fine-line. Minha missão é transformar memórias e imagens em arte eterna na pele, criando peças únicas que contam histórias de forma vívida e autêntica.</div>
                        </div>
                        <div className="bloco3">
                            <div className="bio-title">Especialidade</div>
                            <div className="especialidades">
                                <div className="pont">
                                    <img src="./images/pont.svg"></img>
                                    <div className="especialidade-title">Pontilhismo</div>
                                </div>
                                <div className="realismo">
                                    <img src="./images/realismo.svg"></img>
                                    <div className="especialidade-title">Realismo</div>
                                </div>
                                <div className="fineline">
                                    <img src="./images/fineline.svg"></img>
                                    <div className="especialidade-title">Fine-Line</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="name-bg">LARISSA ARAUJO</div>
                </div>
                {/* ARTISTA 2 */}
                <div className="container-artista-dir">
                    <div className="artista-dir">
                        <img className="artista-foto" src="./images/artista2.png"/>
                        <div className="border-foto-back"/>
                        <div className="border-foto-front"/>
                    </div>
                    <div className="artista-info">
                        <div className="bloco1-dir">
                            <div className="nome">CARLOS EDUARDO</div>
                            <div className="insta-dir">@carlosedutattoo</div>
                        </div>
                        <div className="bloco2-dir">
                            <div className="bio-title">Biografia</div>
                            <div className="bio-text">Tatuador com foco em Blackwork e Pontilhismo, utilizo o contraste do preto com a delicadeza dos pontos para criar tatuagens impactantes e minimalistas. Cada traço é pensado para destacar o corpo, resultando em peças que são verdadeiras obras de arte.</div>
                        </div>
                        <div className="bloco3-dir">
                            <div className="bio-title">Especialidade</div>
                            <div className="especialidades-dir">
                                <div className="pont-dir">
                                    <img src="./images/pont.svg"></img>
                                    <div className="especialidade-title">Pontilhismo</div>
                                </div>
                                <div className="blackwork-dir">
                                    <img src="./images/blackwork.svg"></img>
                                    <div className="especialidade-title">BlackWork</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="name-bg">CARLOS EDUARDO</div>
                </div>
                {/* ARTISTA 3 */}
                <div className="container-artista">
                    <div className="artista">
                        <img className="artista-foto" src="./images/artista3.png"/>
                        <div className="border-foto-back"/>
                        <div className="border-foto-front"/>
                    </div>
                    <div className="artista-info">
                        <div className="bloco1">
                            <div className="nome">FERNANDO SANTOS</div>
                            <div className="insta">@fersantospiercing</div>
                        </div>
                        <div className="bloco2">
                            <div className="bio-title">Biografia</div>
                            <div className="bio-text">Combinando a arte da tatuagem com a precisão da aplicação de piercings, ofereço uma experiência completa para quem busca expressar sua individualidade. Especializado em estilos variados de tatuagem, desde o realismo ao tradicional, e em piercings que realçam a beleza natural, meu objetivo é proporcionar arte corporal que seja única e significativa para cada cliente.</div>
                        </div>
                        <div className="bloco3">
                            <div className="bio-title">Especialidade</div>
                            <div className="especialidades">
                                <div className="realismo">
                                    <img src="./images/realismo.svg"></img>
                                    <div className="especialidade-title">Realismo</div>
                                </div>
                                <div className="piercing">
                                    <img src="./images/piercing.svg"></img>
                                    <div className="especialidade-title">Piercing</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="name-bg">FERNANDO SANTOS</div>
                </div>
                <div className="agende">
                    <div className="agendeTitle1">AGENDE UM<hr className="line2-agende"/></div>
                    <div className="agendeTitle2"><hr className="line1-agende"/>HORÁRIO</div>
                    <div className="agendarSubTitle">GARANTIA DE QUALIDADE EM SUA TATTOO OU APLICAÇÃO DE PIERCINGS!</div>
                    <div className="blockBtnAgendar">
                        <a href='https://wa.me/+5545991166770' style={{textDecoration:'none', color:'#000'}}><div className="btnAgendar"><WhatsAppIcon sx={{marginRight:1, fontSize:32}}/>AGENDAR</div></a>
                        <div className="btnAgenderBorder2"/>
                    </div>
                </div>
            </div>
            <Footer page='ARTISTAS'/>
        </div>
    )
}
export default Artistas;