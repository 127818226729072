import React, { useState } from 'react';
import Grid from '@mui/material/Grid2';
import Footer from "../Components/footer";
import Header from "../Components/header";
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import CloseIcon from '@mui/icons-material/Close';
import { Modal, Box, IconButton } from '@mui/material';
import { styled } from '@mui/system';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

const images = [
    './images/galeria/image.png',
    './images/galeria/image-0.png',
    './images/galeria/image-1.png',
    './images/galeria/image-2.png',
    './images/galeria/image-3.png',
    './images/galeria/image-4.png',
    './images/galeria/image-5.png',
    './images/galeria/image-6.png',
    './images/galeria/image-7.png',
    './images/galeria/image-8.png',
    './images/galeria/image-9.png',
    './images/galeria/image-10.png'
  ];

const ImageBox = styled(Box)(({ theme }) => ({
    position: 'relative',
    width: 275,
    borderRadius: 10,
    height: 355,
    overflow: 'hidden',
    '&:hover': {
      cursor: 'pointer',
      '& img': {
        opacity: 0.6
      },
      '& .zoomIcon': {
        color:'#FFDD00',
        display: 'block',
      },
    },
  }));

const ZoomIcon = styled(ZoomInIcon)(({ theme }) => ({
    color: '#fff',
    fontSize: '3rem',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    display: 'none',
  }));
  
  const StyledModal = styled(Modal)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }));
  
  const CloseButton = styled(IconButton)(({ theme }) => ({
    position: 'absolute',
    top: 10,
    right: 10,
    color: '#fff',
  }));

const Galeria = () => {    
    const [open, setOpen] = useState(false);
    const [currentImage, setCurrentImage] = useState(null);

    const handleOpen = (image) => {
        setCurrentImage(image);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setCurrentImage(null);
    };
    
    return (
        <div className="container">
             <Header/>
             <div className="content">
                <div className="divisor">
                    <div className="divisorTitle1">GALERIA DE</div>
                    <div className="divisorTitle2"><hr className="line1" style={{width:100}}/>TRABALHOS</div>
                    <div className="divisorSubTitle">ESTES SÃO SOMENTE ALGUNS DOS NOSSOS TRABALHOS!</div>
                </div>
                <div className="container-galeria">
                <Grid columns={{ xs: 4, sm: 8, md: 12 }} container spacing={2} justifyContent="center">
                    {images.map((image, index) => (
                    <Grid item key={index} display="flex" justifyContent="center">
                        <ImageBox onClick={() => handleOpen(image)}>
                        <img src={image} alt={`Imagem ${index + 1}`} style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                        <ZoomIcon className="zoomIcon" />
                        </ImageBox>
                    </Grid>
                    ))}
                </Grid>
                <StyledModal open={open} onClose={handleClose}>
                    <Box sx={{ position: 'relative', maxWidth: '90%', maxHeight: '90%' }}>
                        <CloseButton onClick={handleClose}>
                            <CloseIcon />
                        </CloseButton>
                        {currentImage && <img src={currentImage} alt="Imagem ampliada" style={{ width: '100%', height: '100%', objectFit: 'contain' }} />}
                    </Box>
                </StyledModal>
                </div>
                <div className="agende">
                    <div className="agendeTitle1">AGENDE UM<hr className="line2-agende"/></div>
                    <div className="agendeTitle2"><hr className="line1-agende"/>HORÁRIO</div>
                    <div className="agendarSubTitle">GARANTIA DE QUALIDADE EM SUA TATTOO OU APLICAÇÃO DE PIERCINGS!</div>
                    <div className="blockBtnAgendar">
                        <a href='https://wa.me/+5545991166770' style={{textDecoration:'none', color:'#000'}}><div className="btnAgendar"><WhatsAppIcon sx={{marginRight:1, fontSize:32}}/>AGENDAR</div></a>
                        <div className="btnAgenderBorder2"/>
                    </div>
                </div>
            </div>
            <Footer page='GALERIA'/>
        </div>
    )
}
export default Galeria;