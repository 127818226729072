import React, {useState} from "react";
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { useNavigate } from "react-router-dom";

function Header() {
    const [isOpen, setIsOpen] = useState(false);
    const [menuIcon, setMenuIcon] = useState(false);
    const navigate = useNavigate('')
    const toggleMenu = () => {
        setIsOpen(!isOpen);
        setMenuIcon(!menuIcon);
    };
    const navHome = () => {
        navigate('/')
    }
    const navArtistas = () => {
        navigate('/artistas')
    }
    const navGaleria = () => {
        navigate('/galeria')
    }
    const navTattoo = () => {
        navigate('/tattoo')
    }
    const navPiercings = () => {
        navigate('/piercings')
    }
    const navOrcamento = () => {
        navigate('/orcamento')
    }
    const navSobreNos = () => {
        navigate('/sobre-nos')
    }

    return (
        <div className="header-container">
            <div className="header-content">
                <div className="header-menu">
                    <a className="menu-title" onClick={navArtistas}>ARTISTAS</a>
                    <a className="menu-title" onClick={navGaleria}>GALERIA</a>
                    <a className="menu-title" onClick={navTattoo}>TATTOO</a>
                    <img src="./images/logo.svg" className="logo" alt="logo" onClick={navHome}/>
                    <a className="menu-title" onClick={navPiercings}>PIERCING</a>
                    <a className="menu-title" onClick={navOrcamento}>ORÇAMENTO</a>
                    <a className="menu-title" onClick={navSobreNos}>SOBRE NÓS</a>
                </div>
                {menuIcon ?
                 <><CloseRoundedIcon onClick={toggleMenu} sx={{display:(isOpen ? '' : 'none'), color:'#fffff', fontSize:36, marginLeft:1.2}}/></>: 
                 <div className="hamburger-icon" onClick={toggleMenu}>
                    <div className="bar"></div>
                    <div className="bar"></div>
                    <div className="bar"></div>
                </div>
                }
            </div>
            <div className={`mobile-menu ${isOpen ? 'open' : ''}`}>
                <a className="menu-title" onClick={navArtistas}>ARTISTAS</a>
                <a className="menu-title" onClick={navGaleria}>GALERIA</a>
                <a className="menu-title" onClick={navTattoo}>TATTOO</a>
                <a className="menu-title" onClick={navPiercings}>PIERCING</a>
                <a className="menu-title" onClick={navOrcamento}>ORÇAMENTO</a>
                <a className="menu-title" onClick={navSobreNos}>SOBRE NÓS</a>
            </div>
        </div>
    )
}
export default Header;