import {Route, BrowserRouter, Routes,} from "react-router-dom";
import Home from "../Pages/Home";
import Artistas from "../Pages/Artistas";
import Galeria from "../Pages/Galeria";
import Tattoo from "../Pages/Tattoo";
import Piercings from "../Pages/Piercings";
import Orcamento from "../Pages/Orcamento";
import SobreNos from "../Pages/SobreNos";

const Rotas = () => {
    return(
        
        <BrowserRouter>
            <Routes>
                <Route element = {<Home/>} path="/"/>
                <Route element = {<Artistas/>} path="/artistas"/>
                <Route element = {<Galeria/>} path="/galeria"/>
                <Route element = {<Tattoo/>} path="/tattoo"/>
                <Route element = {<Piercings/>} path="/piercings"/>
                <Route element = {<Orcamento/>} path="/orcamento"/>
                <Route element = {<SobreNos/>} path="/sobre-nos"/>
            </Routes>
        </BrowserRouter>
        
    )
}

export default Rotas;