import React, {useState, useEffect, useRef} from 'react';
import banner1 from '../Banners/banner1.png';
import banner2 from '../Banners/banner2.png';
import banner3 from '../Banners/banner3.png';

const banners = [banner1, banner2, banner3];

function Banner() {

    const [currentIndex, setCurrentIndex] = useState(0);
    const [fadeIn, setFadeIn] = useState(true);
    const totalBanners  = banners.length;
    const intervalRef = useRef(null);

    const nextBanner = () => {
        setFadeIn(false);
        setTimeout(() => {
            setCurrentIndex((prevIndex) => (prevIndex + 1) % totalBanners);
            setFadeIn(true);
        }, 300);
    };
    const prevBanner = () => {
        setFadeIn(false);
        setTimeout(() => {
          setCurrentIndex((prevIndex) =>
            prevIndex === 0 ? totalBanners - 1 : prevIndex - 1
          );
          setFadeIn(true);
        }, 300);
    };

    const resetInterval = () => {
        if (intervalRef.current) {
            clearInterval(intervalRef.current);
        }
        intervalRef.current = setInterval(() => {
            nextBanner();
        }, 10000);
    }

    useEffect(() => {
        resetInterval();
        return () => clearInterval(intervalRef.current);
    },[]);

    const handleNext = () => {
        nextBanner();
        resetInterval();
    };
    const handlePrev = () => {
        prevBanner();
        resetInterval();
    };

    return(
        <div className='slider-container'>
            <div className='slider'>
                <img src={banners[currentIndex]} alt={`Banner ${currentIndex + 1}`} className={`banner-image ${fadeIn ? 'fade-in' : 'fade-out'}`}/>
                <button className='prev' onClick={handlePrev}>&#10094;</button>
                <button className='next' onClick={handleNext}>&#10095;</button>
            </div>
            <div className='dots-container'>
                {banners.map((_, index) => (
                    <span
                        key={index}
                        className={`dot ${currentIndex === index ? 'active' : ''}`}
                        onClick={() => setCurrentIndex(index)}
                    />
                ))}                
            </div>
        </div>
    )
}
export default Banner;