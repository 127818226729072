import React from 'react';
import FacebookRoundedIcon from '@mui/icons-material/FacebookRounded';
import InstagramIcon from '@mui/icons-material/Instagram';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

function Footer(props) {

    return(
        <>
    <div className="footer-container">
        
        <div className="follow-us">SIGA-NOS NAS REDES SOCIAIS</div>
        <div className='social-medias'>
            <a href='https://instagram.com' style={{textDecoration:'none', color:'#000'}}><InstagramIcon sx={{marginRight:1, fontSize:32, color:'#FFDD00', cursor:'pointer'}}/></a>
            <a href='https://facebook.com' style={{textDecoration:'none', color:'#000'}}><FacebookRoundedIcon sx={{marginRight:1, fontSize:32, color:'#FFDD00'}}/></a>
            <a href='https://wa.me/+5545991166770' style={{textDecoration:'none', color:'#000'}}><WhatsAppIcon sx={{marginRight:1, fontSize:32, color:'#FFDD00'}}/></a>
        </div>
        <div className="rights">©2024 - Tattoo Studio - Todos os direitos reservados.</div>
        <a className="developer" href='https://sigmasystems.com.br'><div style={{display:'flex'}}>Desenvolvido por:<img src="./images/logoFooter.svg" className="logoSigma" alt="sigmaLogo"></img></div></a>
    </div>
    <div className='footer-page'>{props.page}</div>
    </>
    )
};
export default Footer;