import Banner from "../Components/banner";
import Footer from "../Components/footer";
import Header from "../Components/header";
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

const Home = () => {    
    
    return (
        <div className="container">
            <Header/>
            <Banner/>
            <div className="content">
                <div className="triangulo-left"/>
                <div className="divisor" style={{marginTop:40}}>
                    <div className="divisorTitle1">FAÇA SUA NOVA</div>
                    <div className="divisorTitle2"><hr className="line1"/>TATTOO</div>
                    <div className="divisorSubTitle">NÃO PERCA TEMPO E FAÇA SUA TATTOO DOS SONHOS</div>
                    <a href='https://wa.me/+5545991166770' style={{textDecoration:'none', color:'#000'}}><div className="btnAgendar"><WhatsAppIcon sx={{marginRight:1, fontSize:32}}/>AGENDAR</div></a>
                    <div className="btnAgenderBorder"/>
                </div>
            </div>   
            <Footer page='TATTOO'/>
        </div>
    )
}
export default Home;